import React from "react";
import styles from "./InputLayout.module.scss";

const InputLayout = ({ label, type, value, onChange, error, placeholder }) => {
  return (
    <div className={styles.inputWrapper}>
      {label && <label className={styles.label}>{label}</label>}
      <input
        type={type}
        className={`${styles.input} ${error ? styles.error : ""}`}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

export default InputLayout;
