import React, { useCallback } from "react";
import styles from "./dragAndDrop.module.scss";
import { useDropzone } from "react-dropzone";
import { motion } from "motion/react";
import ImagePreview from "./ImgagePreview";
import { uploadToCloudinary } from "../../../../Server/cloudinary";

function DragAndDrop({ previews, setPreviews, multiple }) {
  // Handle file drop
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    console.log("Rejected files:", rejectedFiles);

    if (acceptedFiles.length) {
      const newPreviews = acceptedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      if (multiple) {
        setPreviews((prevState) => [...prevState, ...newPreviews]);
      } else {
        setPreviews(newPreviews);
      }
    }
  }, []);

  // Dropzone configuration
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    multiple: multiple,
  });

  return (
    <div className={styles.container}>
      <motion.div
        initial={{
          borderColor: "rgb(204, 204, 204)",
          color: "rgb(204, 204, 204)",
        }}
        animate={{
          borderColor: isDragActive ? "rgb(57, 205, 55)" : "rgb(204, 204, 204)",
          color: isDragActive ? "#42c940" : "rgb(130, 130, 130)",
        }}
        {...getRootProps()}
        className={styles.dropzone}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Rilascia le immagini qui...</p>
        ) : (
          <p>Trascina le immagini qui o clicca per selezionarle.</p>
        )}
        {!multiple && previews.length === 1 && (
          <ImagePreview src={previews[0]} setPreviews={setPreviews} index={0} />
        )}
      </motion.div>
    </div>
  );
}

export default DragAndDrop;
