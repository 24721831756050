import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Projects from "./dahsboardRoutes/projects/Project";
import Events from "./dahsboardRoutes/events/Events";
import Settings from "./dahsboardRoutes/settings/Settings";
import NewProjectModal from "./dahsboardRoutes/projects/NewProjectModal";
import UpdateProjectModal from "./dahsboardRoutes/projects/UpdateProjectModal";
import NewEventModal from "./dahsboardRoutes/events/NewEventModal";
import toast from "react-hot-toast";
import UpdateEventModal from "./dahsboardRoutes/events/UpdateEventModal";

function DashboardRoutes() {
  useEffect(() => {
    toast.success("Login effettuato con successo");
  }, []);
  return (
    <>
      <Routes>
        <Route path="/projects" element={<Projects />} />
        <Route path="/events" element={<Events />} />
        <Route path="/settings" element={<Settings />} />
        <Route
          path="/projects/newproject"
          element={
            <>
              <Projects /> <NewProjectModal />
            </>
          }
        />
        <Route
          path="/projects/:projectID"
          element={
            <>
              <Projects /> <UpdateProjectModal />
            </>
          }
        />
        <Route
          path="/events/new-event"
          element={
            <>
              <Events /> <NewEventModal />
            </>
          }
        />
        <Route
          path="/events/:event_id"
          element={
            <>
              <Events /> <UpdateEventModal />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default DashboardRoutes;
