import React, { JSX, useEffect, useState } from "react";
import { AnimatePresence, motion } from "motion/react";
import { useTranslation } from "react-i18next";
import AnimatedText from "../Layouts/AnimatedText";
import { useWebsiteContext } from "../hooks/useWebsiteContext";

function SplashScreen() {
  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useWebsiteContext();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    console.log(isLoading);
  }, [isLoading]);
  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          key={"spash-screen"}
          initial={{ y: 0 }}
          animate={{ y: 0 }}
          exit={{
            y: "-100%",
            transition: {
              duration: 1.2,
            },
          }}
          transition={{
            type: "spring",
            dumping: 16,
            friction: 200,
            stiffness: 30,
          }}
          className="splashScreenContainer"
        >
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 1,
              bounce: 0.2,
              type: "ease-out",
              delay: 0.5,
            }}
            className="logo"
          ></motion.div>
          <motion.div className="companyName">
            <AnimatedText
              isLoading={isLoading}
              className="logoStrong"
              delay={0.9}
            >
              RAD
            </AnimatedText>
            <AnimatedText
              isLoading={isLoading}
              className="logoGrey"
              delay={0.7}
            >
              architetture
            </AnimatedText>
          </motion.div>
          <AnimatedText
            isLoading={isLoading}
            delay={0.9}
            className={"companyLocation"}
          >
            {t("splash.location")}
          </AnimatedText>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default SplashScreen;
