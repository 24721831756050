import React, { createContext, useContext, useState, ReactNode } from "react";

interface WebsiteContextType {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

const defaultContextValue: WebsiteContextType = {
  isLoading: true,
  setIsLoading: () => {},
};

const WebsiteContext = createContext<WebsiteContextType>(defaultContextValue);

interface WebsiteContextProvider {
  children: ReactNode;
}

export const WebsiteProvider: React.FC<WebsiteContextProvider> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(
    defaultContextValue.isLoading
  );

  return (
    <WebsiteContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </WebsiteContext.Provider>
  );
};

export const useWebsiteContext = (): WebsiteContextType => {
  return useContext(WebsiteContext);
};
