import React from "react";
import styles from "./dashboard.module.scss";

export default function BackofficeNavbar() {
  return (
    <div className={styles.navbarWrapper}>
      <div className={styles.navbarLogo} />
      <span style={{ fontSize: "22px" }}>
        <strong>RAD</strong> architetture
      </span>
    </div>
  );
}
