import React, { useState } from "react";
import CustomModal from "../layouts/custom_modal/CustomModal";
import { useNavigate } from "react-router-dom";
import CustomInput from "../layouts/custom_input/CustomInput";
import styles from "../layouts/custom_input/customInput.module.scss";
import DragAndDrop from "../../drag_and_drop/DragAndDrop";
import { createEventWithData } from "../../../../../Server/db";
import { useDashboardContext } from "../../../hooks/useDashboardContext";
import toast from "react-hot-toast";

function NewEventModal() {
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useDashboardContext();

  //State to manage inputs
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imagePreview, setImagePreview] = useState([]);
  const [location, setLocation] = useState();
  const [eventDate, setEventDate] = useState();
  // State to manage form submission
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form validation
    if (
      !title ||
      !description ||
      imagePreview.length === 0 ||
      !location ||
      !eventDate
    ) {
      toast.error("Compila tutti i campi.");
      return;
    }
    setIsLoading(true);

    setIsSubmitting(true);

    try {
      const eventData = {
        title,
        description,
        location,
        event_date: eventDate,
      };

      createEventWithData(eventData, imagePreview[0].file)
        .then(() => navigate(-1))
        .then(() => toast.success("Evento creato con successo."));
    } catch (error) {
      console.error(error);
      toast.error("Upload non riuscito. Rirpova.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Sei sicuro? Procedendo perderai i progressi non salvati?"
    );

    if (userConfirmed) {
      navigate(-1);
    }
  };

  return (
    <CustomModal
      title={"Nuovo Evento"}
      description={
        "Inserisci i dettagli dell'evento e clicca “Conferma” per salvarlo."
      }
      isSubmitting={isSubmitting}
      handleConfirm={(e) => handleSubmit(e)}
      handleCancel={() => handleCancel()}
    >
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <CustomInput
          label={"Nome evento"}
          type={"text"}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder=""
          error={formError}
          helperText={""}
          required={true}
          disabled={isSubmitting}
          name={"name"}
          className=""
          errorMessage={""}
        />

        <div className={styles.inputContainer}>
          <label htmlFor="description" className={styles.label}>
            Descrizione dell'evento
            <span style={{ color: "red" }}>*</span>
          </label>
          <textarea
            className={styles.input}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{
              width: "100%",
              resize: "vertical",
              fontSize: "13px",
              borderColor: formError ? "red" : "grey",
            }}
            id="description"
            name="Text1"
            cols="40"
            required
            rows="5"
          ></textarea>
          <div className={styles.splitInputs}>
            <div className={styles.dropppable}>
              <DragAndDrop
                setPreviews={setImagePreview}
                previews={imagePreview}
                multiple={false}
              />
            </div>
            <div className={styles.splitSection}>
              <CustomInput
                label={"Data Evento"}
                type={"date"}
                value={eventDate}
                onChange={(e) => setEventDate(e.target.value)}
                placeholder=""
                error={formError}
                helperText={""}
                required={true}
                disabled={isSubmitting}
                name={"name"}
                className=""
                errorMessage={""}
              />
            </div>
            <div className={styles.splitSection}>
              <CustomInput
                label={"Luogo"}
                type={"text"}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder=""
                error={formError}
                helperText={""}
                required={true}
                disabled={isSubmitting}
                name={"name"}
                className=""
                errorMessage={""}
              />
            </div>
          </div>
        </div>
      </form>
    </CustomModal>
  );
}

export default NewEventModal;
