import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import styles from "./dashboard.module.scss";
// Icons imports
import { CgWebsite } from "react-icons/cg";
import { PiBlueprint } from "react-icons/pi";
import { MdEventAvailable } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";

export default function ThemedIcon({ path, title }) {
  function renderIcon(path, isActive) {
    switch (path) {
      case "/back-office/dashboard/projects":
        return (
          <PiBlueprint className={isActive ? styles.activeIcon : styles.icon} />
        );
      case "/back-office/dashboard/events":
        return (
          <MdEventAvailable
            className={isActive ? styles.activeIcon : styles.icon}
          />
        );
      case "/":
        return (
          <CgWebsite className={isActive ? styles.activeIcon : styles.icon} />
        );
      case "/back-office/dashboard/settings":
        return (
          <RiUserSettingsLine
            className={isActive ? styles.activeIcon : styles.icon}
          />
        );
      default:
        return null;
    }
  }

  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        isActive
          ? `${styles.iconContainer} ${styles.active}`
          : styles.iconContainer
      }
    >
      {({ isActive }) => (
        <>
          {isActive && (
            <motion.div
              transition={{ duration: 0.3 }}
              className={styles.underline}
              layoutId="underline"
            />
          )}
          <div className={styles.linkContent}>
            {renderIcon(path, isActive)}
            <p className={styles.linkText}>{title}</p>
          </div>
        </>
      )}
    </NavLink>
  );
}
