import React, { useCallback, useEffect, useState, useMemo } from "react";
import PageLayout from "../layouts/PageLayout";
import FunctionBar from "../FunctionBar";
import { fetchProjectsPreviews } from "../../../../../Server/supabase";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styles from "../dahboardSections.module.scss";
import { SortableTable } from "../SortableTable";
import toast from "react-hot-toast";
import { deleteProjects } from "../../../../../Server/db";
import ProjecttableRow from "./ProjectRow";

export default function Projects() {
  const [projects, setProjects] = useState([]);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  const updateSortedProject = useMemo(() => {
    return [...projects].sort((a, b) => {
      const posA = a.positions[0]?.position || Infinity;
      const posB = b.positions[0]?.position || Infinity;
      return posB - posA;
    });
  }, [projects]);

  useEffect(() => {
    setSortedProjects(
      updateSortedProject.filter((element) =>
        element.title.toLocaleLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [updateSortedProject, searchInput]);

  async function handlFetchUpdate() {
    try {
      const projectsArray = await fetchProjectsPreviews();
      setProjects(projectsArray);
    } catch (error) {
      console.error("Error fetching project:", error);
    } finally {
      setSearchInput("");
    }
  }

  const location = useLocation();
  useEffect(() => {
    handlFetchUpdate();
  }, [location.pathname]);

  const addProject = useCallback(() => {
    navigate("/back-office/dashboard/projects/newproject");
  }, []);

  const handleDeleteProjects = async () => {
    setLoading(true);
    try {
      await deleteProjects(selectedProjects);
      setSelectedProjects([]);
      handlFetchUpdate();
    } catch (error) {
      console.error(error);
      toast.success("Si è verificato un errore. Riprova.");
    } finally {
      setLoading(false);
      toast.success("Progetti eliminati con successo.");
    }
  };

  function handleUpdate() {
    setLoading(true);
    try {
      handlFetchUpdate();
    } catch (error) {
      console.error(error);
      toast.error("Si è verificato un errore durante l'aggiornamento.");
    } finally {
      setLoading(false);
      toast.success("Progetti aggiornati.");
    }
  }

  function formatDate(rawDate) {
    const date = new Date(rawDate);
    const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString(
      [],
      { hour: "2-digit", minute: "2-digit" }
    )}`;
    return formattedDate;
  }

  return (
    <PageLayout>
      <h2 className={styles.title}>Progetti</h2>
      <FunctionBar
        tableTitle="Progetti"
        sortedArray={sortedProjects}
        projects={projects}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        fetchUpdate={handlFetchUpdate}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleAdd={addProject}
        handleUpdate={handleUpdate}
        handleDelete={handleDeleteProjects}
        isLoading={loading}
      />
      <SortableTable
        isSortable={true}
        selectedProjects={selectedProjects}
        sortedItems={sortedProjects}
        setSortedItems={setSortedProjects}
        setSelectedProjects={setSelectedProjects}
        loading={loading}
        setLoading={setLoading}
        onRowClick={(project) => navigate(`/projects/${project.id}`)}
        headers={["", "Foto", "Nome", "Creato il", "Modificato il", ""]}
        RowComponent={ProjecttableRow}
        formatDate={formatDate}
      />
    </PageLayout>
  );
}
