import React from "react";
import { useLocation } from "react-router-dom";

import styles from "./dashboard.module.scss";
import ThemedIcon from "./ThemedIcon";

export default function Sidebar() {
  const menuPathsTop = [
    { title: "Projects", path: "/back-office/dashboard/projects" },
    { title: "Events", path: "/back-office/dashboard/events" },
  ];

  const menuPathsBottom = [
    { title: "Website", path: "/" },
    { title: "Settings", path: "/back-office/dashboard/settings" },
  ];

  const location = useLocation();
  return (
    <div className={styles.navWrapper}>
      <div className={styles.sidebarContainer}>
        <div className={styles.sidebarSection}>
          {menuPathsTop.map((element, index) => (
            <ThemedIcon
              key={`top-${index}`}
              path={element.path}
              title={element.title}
            />
          ))}
        </div>
        <div className={styles.sidebarSection}>
          {menuPathsBottom.map((element, index) => (
            <ThemedIcon
              key={`bottom-${index}`}
              path={element.path}
              title={element.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
