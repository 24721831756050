import React, { JSX } from "react";
import AnimatedText from "./AnimatedText";

interface AnimatedUnderlineButtonProps {
  children: React.ReactNode;
  className?: string;
  isLoading: boolean;
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

function AnimatedUnderlineButton({
  children,
  className = "",
  isLoading,
  handleClick,
}: AnimatedUnderlineButtonProps): JSX.Element {
  return (
    <AnimatedText delay={1} className={className} isLoading={isLoading}>
      <div onClick={handleClick} className={`animated-underline ${className}`}>
        {children}
      </div>
    </AnimatedText>
  );
}

export default AnimatedUnderlineButton;
