import React, { useState, useEffect } from "react";
import CustomModal from "../layouts/custom_modal/CustomModal";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../layouts/custom_input/CustomInput";
import styles from "../layouts/custom_input/customInput.module.scss";
import DragAndDrop from "../../drag_and_drop/DragAndDrop";
import ImagePreview from "../../drag_and_drop/ImgagePreview";
import { fetchProjectsByID } from "../../../../../Server/supabase";
import CurrentImagePreview from "../../drag_and_drop/CurrentImagePreview";
import { updateProjectWithImages } from "../../../../../Server/db";
import toast from "react-hot-toast";

function UpdateProjectModal() {
  const navigate = useNavigate();

  //State to manage inputs
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imagePreview, setImagePreview] = useState([]);
  const [location, setLocation] = useState("");
  const [state, setState] = useState("");
  const [projectImages, setProjectImages] = useState([]);
  const [authors, setAuthors] = useState([{ name: "", description: "" }]);
  const [client, setClient] = useState("");
  const [currentImages, setCurrentImages] = useState([]);
  const [previewID, setPreviewID] = useState(0);
  const [previewsPublicID, setPreviowsPublicID] = useState("");
  // State to manage form submission
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError("");

    // Form validation
    if (
      !title ||
      !description ||
      imagePreview.length === 0 ||
      !location ||
      !state ||
      authors.length === 0 ||
      !client
    ) {
      setFormError(true);
      return;
    }

    setIsSubmitting(true);

    const projectDetails = {
      title,
      description,
      location,
      state,
      client,
    };

    const filesArray = projectImages.map((image) => image.file);

    try {
      updateProjectWithImages(
        Number(projectID),
        projectDetails,
        authors,
        filesArray,
        imagePreview[0].file,
        previewID,
        previewsPublicID
      )
        .then(() => navigate(-1))
        .then(() => toast.success("Progetto aggiornato correttamente"));
    } catch (error) {
      console.error(error);
      toast.error("salvataggio non riuscito. Riprova");
    }
  };

  const { projectID } = useParams();

  useEffect(() => {
    async function fetchData(projectID) {
      try {
        const data = await fetchProjectsByID(projectID);
        if (data) {
          const authors = data[0].authors.map((author) => ({
            name: author.name,
            description: author.description,
            id: author.id,
            project_id: projectID,
          }));
          setTitle(data[0].title);
          setDescription(data[0].description);
          setState(data[0].state);
          setLocation(data[0].location);
          setClient(data[0].client);
          setAuthors(authors);
          setImagePreview(data[0].previews);
          setCurrentImages(data[0].images);
          setPreviewID(data[0].previews[0].id);
          setPreviowsPublicID(data[0].previews[0].public_id);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData(projectID);
  }, [projectID]);

  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Sei sicuro? Procedendo perderai i progressi non salvati?"
    );

    if (userConfirmed) {
      navigate(-1);
    }
  };

  const handleAddAuthor = () => {
    setAuthors([...authors, { name: "", description: "" }]);
  };

  const handleRemoveAuthor = (index) => {
    setAuthors(authors.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    const updatedAuthors = [...authors];
    updatedAuthors[index][field] = value;
    setAuthors(updatedAuthors);
  };

  return (
    <CustomModal
      title={"Aggiorna Progetto"}
      description={
        "Aggiorna i dati del progetto e clicca “Conferma” rendere i cambiamenti effettivi."
      }
      handleConfirm={(e) => handleSubmit(e)}
      handleCancel={() => handleCancel()}
      isSubmitting={isSubmitting}
    >
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <div className={styles.double}>
          <div className={styles.splitSection}>
            <CustomInput
              label={"Nome progetto"}
              type={"text"}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder=""
              error={formError}
              helperText={""}
              required={true}
              disabled={isSubmitting}
              name={"name"}
              className=""
              errorMessage={""}
            />
          </div>
          <div className={styles.splitSection}>
            <CustomInput
              label={"Cliente"}
              type={"text"}
              value={client}
              onChange={(e) => setClient(e.target.value)}
              placeholder=""
              error={formError}
              helperText={""}
              required={true}
              disabled={isSubmitting}
              name={"name"}
              className=""
              errorMessage={""}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="description" className={styles.label}>
            Descrizione del progetto
            <span style={{ color: "red" }}>*</span>
          </label>
          <textarea
            className={styles.input}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{
              width: "100%",
              resize: "vertical",
              fontSize: "13px",
              borderColor: formError ? "red" : "grey",
            }}
            id="description"
            name="Text1"
            cols="40"
            required
            rows="5"
          ></textarea>
          <div className={styles.splitInputs}>
            <div className={styles.dropppable}>
              <DragAndDrop
                setPreviews={setImagePreview}
                previews={imagePreview}
                multiple={false}
              />
            </div>
            <div className={styles.splitSection}>
              <CustomInput
                label={"Località"}
                type={"text"}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder=""
                error={formError}
                helperText={""}
                required={true}
                disabled={isSubmitting}
                name={"name"}
                className=""
                errorMessage={""}
              />
            </div>
            <div className={styles.splitSection}>
              <CustomInput
                label={"Stato Progetto"}
                type={"text"}
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder=""
                error={formError}
                helperText={""}
                required={true}
                disabled={isSubmitting}
                name={"name"}
                className=""
                errorMessage={""}
              />
            </div>
          </div>
          <div className={styles.multiDroppable}>
            <DragAndDrop
              setPreviews={setProjectImages}
              previews={projectImages}
              multiple={true}
            />
          </div>
          {projectImages.length > 0 && (
            <div className={styles.multiDroppable}>
              {projectImages.map((src, index) => (
                <ImagePreview
                  key={`project images-${index}`}
                  index={index}
                  src={src}
                  setPreviews={setProjectImages}
                />
              ))}
            </div>
          )}
          {currentImages.length > 0 && (
            <>
              <h4>Immagini del progetto</h4>

              <div className={styles.imagesContainer}>
                {currentImages.map((image, index) => (
                  <CurrentImagePreview
                    key={`project images-${index}`}
                    index={index}
                    src={image.image_url}
                    publicID={image.public_id}
                    imageID={image.id}
                    setPreviews={setCurrentImages}
                  />
                ))}
              </div>
            </>
          )}
          <div className={styles.doubleButton}>
            {authors.map((author, index) => (
              <>
                <div
                  key={`Autore ${index + 1}`}
                  className={styles.splitSection}
                >
                  <CustomInput
                    label={`Autore ${index + 1}`}
                    type="text"
                    value={author.name}
                    onChange={(e) =>
                      handleInputChange(index, "name", e.target.value)
                    }
                    placeholder="Autore"
                    error={formError}
                    required
                    disabled={isSubmitting}
                  />
                </div>
                <div
                  key={`Contributo ${index + 1}`}
                  className={styles.splitSection}
                >
                  <CustomInput
                    label="Contributo"
                    type="text"
                    value={author.description}
                    onChange={(e) =>
                      handleInputChange(index, "description", e.target.value)
                    }
                    placeholder="Contributo"
                    error={formError}
                    required
                    disabled={isSubmitting}
                  />
                </div>
                <button
                  onClick={() => handleRemoveAuthor(index)}
                  className={styles.authorButton}
                >
                  Elimina
                </button>
              </>
            ))}
            <button
              className={styles.authorButton}
              onClick={() => handleAddAuthor()}
            >
              Aggiungi autore
            </button>
          </div>
        </div>
      </form>
    </CustomModal>
  );
}

export default UpdateProjectModal;
