import React, { useState } from "react";
import styles from "./loginStyles.module.scss";
import InputLayout from "./InputLayout";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuthlistener";
import supabase from "../../../Server/supabase";
import toast from "react-hot-toast";

export default function Reset() {
  const { user, setUser } = useAuth();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    login: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function validateForm() {
    const newErrors = {};
    if (!email) {
      newErrors.email = `Inserisci la mail`;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        let { data, error } = await supabase.auth.resetPasswordForEmail(email);

        if (error) {
          setErrors({ login: error.message });
          setLoading(false);
        } else {
          toast.success("Email inviata con successo.");
          console.log("Email reset sent succesfully to:", email);
          setEmail("");
        }
        setLoading(false);
      } catch (error) {
        console.error("There was an error.", error.message);
        setLoading(false);
        setErrors({ login: "Invio non riuscito. Riprova." });
        toast.error("Invio non riuscito. Riprova.");
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <h2 style={{ textAlign: "left", margin: "2rem" }}>Resetta password</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <InputLayout
            label="Email"
            type="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            error={errors.email}
            placeholder="Enter your email"
          />
          <button disabled={loading} className={styles.button} type="submit">
            Invia
          </button>
          <p
            style={{ textAlign: "center", fontSize: "12px", color: "#ab2913" }}
          >
            {errors.login}
          </p>
        </form>

        <span
          onClick={() => navigate("/back-office/login")}
          role="button"
          tabIndex={0}
          className={styles.secondaryButton}
        >
          Ricordi la password? Accedi
        </span>
      </div>
      <div className={styles.logoContainer}>
        <div className={styles.logo} />
        <span className={styles.logoText}>
          <strong>RAD</strong> architetture
        </span>
        <span className={styles.secondaryText}>Florence</span>
      </div>
    </div>
  );
}
