import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./i18n/resources/en.json";
import it from "./i18n/resources/it.json";

// Available languages and fallback options
const resources = {
  en: { translation: en },
  it: { translation: it },
};

i18n
  .use(LanguageDetector) // Use the language detector plugin
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: [
        "navigator",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
      ],
      caches: ["localStorage", "cookie"],
    },
    fallbackLng: ["it", "en"], // Fallback languages
    interpolation: {
      escapeValue: false, // React already escapes
    },
  });

export default i18n;
