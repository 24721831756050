import React, { useState, useEffect } from "react";
import PageLayout from "../layouts/PageLayout";
import styles from "../dahboardSections.module.scss";
import Setting_Section from "../layouts/setting_section/Setting_Section";
import CustomInput from "../layouts/custom_input/CustomInput";
import CustomButton from "../layouts/custom_button/customButton";
import Loader from "../layouts/Loader";
import supabase from "../../../../../Server/supabase";
import toast from "react-hot-toast";
import { fetchUsers } from "../../../../../Server/supabase";
import { useAuth } from "../../../hooks/useAuthlistener";

export default function Projects() {
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    fetchUsersData(user);
  }, [user]);

  async function fetchUsersData(user) {
    if (user) {
      const userList = await fetchUsers();
      const otherUsers = userList.users.filter(
        (element) => element.id !== user.id
      );
      setUsers(otherUsers);
    }
  }

  async function handlePasswordMagicLink(email) {
    try {
      let { data, error } = await supabase.auth.resetPasswordForEmail(email);
      if (error) {
        toast.error("Si è verificato un errore. Riprova.");
      }
      toast.success("Email inviata con successo.");
      console.log(data);
      return data;
    } catch (error) {
      console.error("Si è verificato un errore:", error);
    }
  }

  async function handleEmailChange(newEmail) {
    try {
      const { data, error } = await supabase.auth.updateUser({
        email: newEmail,
      });
      if (error) {
        toast.error("Si è verificato un errore. Riprova.");
      }
      toast.success("Email aggiornata con successo.");
      console.log(data);
      return data;
    } catch (error) {
      console.error("Si è verificato un errore:", error);
    }
  }

  async function handlePasswordReset(password, newPassword) {
    if (password === newPassword) {
      try {
        const { data, error } = await supabase.auth.updateUser({
          password: newPassword,
        });
        if (error) {
          console.log();
          toast.error(error.message);
        } else {
          toast.success("Nuova impostata con successo.");
          console.log(data);
        }
        return data;
      } catch (error) {
        console.error("Si è verificato un errore:", error);
      }
    }
  }

  async function handleNewUser(email) {
    try {
      let { data, error } = await supabase.auth.admin.inviteUserByEmail(email);

      if (error) {
        toast.error(error.message);
      } else {
        toast.success("Email inviata con successo.");
        console.log(data);
      }
      return data;
    } catch (error) {
      console.error("Si è verificato un errore:", error);
    }
  }

  async function handleLogOut() {
    try {
      let { error } = await supabase.auth.signOut();
      if (error) {
        toast.error("Si è verificato un errore. Riprova.");
      }
    } catch (error) {
      toast.error("Si è verificato un errore. Riprova.");
    }
  }

  return (
    <PageLayout>
      <h2 className={styles.title}>Settings</h2>
      <div className={styles.settingContainer}>
        <Setting_Section>
          <h4>Reimposta password</h4>
          <div className={styles.sectionInline}>
            <CustomInput
              label={"Nuova Password"}
              type={"password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder=""
              error={newPasswordError.length > 0}
              helperText={""}
              required={true}
              disabled={isLoading}
              name={"password"}
              className=""
              errorMessage={newPasswordError}
            />
            <CustomInput
              label={"Ripeti la password"}
              type={"password"}
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              placeholder=""
              error={null}
              helperText={""}
              required={true}
              disabled={isLoading}
              name={"name"}
              className=""
              errorMessage={""}
            />
            <CustomButton
              title={"Conferma"}
              action={() => handlePasswordReset(newPassword, repeatPassword)}
              type={"confirm"}
              icon={""}
            />
          </div>
        </Setting_Section>
        <Setting_Section>
          <h4>Modifica Email</h4>
          <div className={styles.sectionInline}>
            <CustomInput
              label={"Nuova email"}
              type={"email"}
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder=""
              error={null}
              helperText={""}
              required={true}
              disabled={isLoading}
              name={"new-email"}
              className=""
              errorMessage={""}
            />
            <CustomButton
              title={"Conferma"}
              action={() => handleEmailChange(newEmail)}
              type={"confirm"}
              icon={""}
            />
          </div>
        </Setting_Section>
        <Setting_Section>
          <h4>Utenti</h4>
          {users.map((el, index) => (
            <div key={`user-${index}`} className={styles.sectionInline}>
              <span>{el.email}</span>
              <CustomButton
                title={"Invia Password Reset"}
                action={() => handlePasswordMagicLink(el.email)}
                type={"confirm"}
                icon={""}
              />
            </div>
          ))}
        </Setting_Section>
        <Setting_Section>
          <CustomButton
            title={"Log out"}
            type={"delete"}
            action={() => handleLogOut()}
          />
        </Setting_Section>
      </div>
      {isLoading && <Loader />}
    </PageLayout>
  );
}
