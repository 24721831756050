import React, { useState } from "react";
import styles from "./loginStyles.module.scss";
import InputLayout from "./InputLayout";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuthlistener";
import supabase from "../../../Server/supabase";
export default function Login() {
  const { setUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    login: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function validateForm() {
    const newErrors = {};
    if (!email) {
      newErrors.email = `Inserisci l'username`;
    }
    if (!password) {
      newErrors.password = `Inserisci la password`;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateForm()) {
      try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        if (error) {
          setErrors({ login: error.message });
          setLoading(false);
        } else {
          console.log("Logged in successfully:", data.user);
          setUser(data.user);
          navigate("/back-office/dashboard/projects");
        }
      } catch (error) {
        console.error("Login unsuccesfull.", error.message);
        setLoading(false);
        setErrors({ login: "Login non riuscito." });
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <h2 style={{ textAlign: "left", margin: "2rem" }}>Login</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <InputLayout
            label="Email"
            type="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            error={errors.email}
            placeholder="Enter your email"
          />
          <InputLayout
            label="Password"
            type="password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            error={errors.password}
            placeholder="Enter your password"
          />
          <button disabled={loading} className={styles.button} type="submit">
            Accedi
          </button>
          <p
            style={{ textAlign: "center", fontSize: "12px", color: "#ab2913" }}
          >
            {errors.login}
          </p>
        </form>

        <span
          role="button"
          tabIndex={0}
          onClick={() => navigate("/back-office/reset")}
          className={styles.secondaryButton}
        >
          Password dimenticata?
        </span>
      </div>
      <div className={styles.logoContainer}>
        <div className={styles.logo} />
        <span className={styles.logoText}>
          <strong>RAD</strong> architetture
        </span>
        <span className={styles.secondaryText}>Florence</span>
      </div>
    </div>
  );
}
