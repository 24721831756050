import React from "react";
import styles from "./dahboardSections.module.scss";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { updatePositionsInSupabase } from "../../../../Server/supabase";

export function SortableTable({
  isSortable,
  selectedProjects = [],
  setSelectedProjects,
  headers,
  sortedItems,
  setSortedItems,
  loading,
  setLoading,
  RowComponent,
  formatDate,
}) {
  function handleCheckboxChange(project) {
    setSelectedProjects((prevSelectedProjects) => {
      if (prevSelectedProjects.includes(project)) {
        return prevSelectedProjects.filter(
          (selectedProject) => selectedProject !== project
        );
      } else {
        return [...prevSelectedProjects, project];
      }
    });
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over) return;

    try {
      setLoading(true);

      if (active.id !== over.id) {
        setSortedItems((prevItems) => {
          const oldIndex = prevItems.findIndex((item) => item.id === active.id);
          const newIndex = prevItems.findIndex((item) => item.id === over.id);

          const updatedItems = arrayMove(prevItems, oldIndex, newIndex);

          const updatedPositions = updatedItems.map((item, index) => ({
            id: item.positions[0].id,
            project_id: item.id,
            position: updatedItems.length - index + 1,
          }));

          updatePositionsInSupabase(updatedPositions);
          return updatedItems;
        });
      }
    } catch (error) {
      console.error("Error updating the order:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <div className={styles.table}>
        <div className={styles.header}>
          {headers.map((element, index) => (
            <div key={index} className={styles.headerCell}>
              {element}
            </div>
          ))}
        </div>
        <SortableContext items={sortedItems} disabled={loading}>
          <div className={styles.content}>
            {sortedItems.map((element, index) => (
              <RowComponent
                index={index}
                key={element.id}
                element={element}
                formatDate={formatDate}
                isSortable={isSortable}
                handleCheckboxChange={handleCheckboxChange}
                selectedProjects={selectedProjects}
                setSelectedProjects={setSelectedProjects}
              />
            ))}
          </div>
        </SortableContext>
      </div>
    </DndContext>
  );
}
