import {
  updateProject,
  createProject,
  deleteImageByID,
  deleteProjectByID,
  deleteEventByID,
  createEvent,
  updateEvent,
} from "./supabase";
import {
  uploadToCloudinary,
  uploadMultipleToCloudinary,
  deleteImageFromCloudinary,
  uploadEventPosterToCloudinary,
} from "./cloudinary";

export async function createNewProjectWithImages(
  projectDetails,
  authors,
  images, // Array or FileList of images
  preview // Single preview image file
) {
  try {
    // Ensure images is an array
    const imagesArray = Array.isArray(images) ? images : Array.from(images);

    // Upload images (multiple files)
    const imagesUrls = await uploadMultipleToCloudinary(imagesArray);

    // Extract the first file from the FileList for preview
    const previewFile = preview instanceof FileList ? preview[0] : preview;

    // Upload preview (single file)
    const previewUrl = await uploadToCloudinary(previewFile);

    // Ensure all uploads are successful before proceeding
    if (imagesUrls.length > 0 && previewUrl) {
      // Pass the URLs to createProject
      await createProject(projectDetails, authors, imagesUrls, previewUrl);
    } else {
      console.error("Failed to upload all assets.");
    }
  } catch (error) {
    console.error("Error creating project with images:", error);
    throw error;
  }
}

export async function updateProjectWithImages(
  id,
  projectDetails,
  authors,
  images, // Array or FileList of images
  preview, // Single preview image file
  previewID,
  previewsPublicID
) {
  try {
    let previewUrl;
    let imagesUrls;
    let previewWithID;
    // Ensure images is an array
    const imagesArray = Array.isArray(images) ? images : Array.from(images);

    // Upload images (multiple files)
    if (images.length > 0) {
      imagesUrls = await uploadMultipleToCloudinary(imagesArray);
    }

    // Extract the first file from the FileList for preview
    const previewFile = preview instanceof FileList ? preview[0] : preview;

    // Upload preview (single file)
    if (previewFile !== undefined) {
      await deleteImageFromCloudinary(previewsPublicID);
      previewUrl = await uploadToCloudinary(previewFile);
      previewWithID = { ...previewUrl, id: previewID };
    }

    // Ensure all uploads are successful before proceeding
    // Pass the URLs to createProject
    await updateProject(id, projectDetails, authors, imagesUrls, previewWithID);
  } catch (error) {
    console.error("Error updating project with images:", error);
    throw error;
  }
}

export async function deleteImage(id, publicID) {
  try {
    await deleteImageByID(id);
    await deleteImageFromCloudinary(publicID);
  } catch (error) {
    console.error("Error deleting image:", error);
  }
}

export async function deleteProjects(projects) {
  const confirmation = window.confirm(
    `Sei sicuro di voler procedere? L'azione è irreversibile`
  );

  if (!confirmation) {
    return;
  }

  if (!Array.isArray(projects) || projects.length === 0) {
    console.warn("No projects provided for deletion.");
    return;
  }

  try {
    for (const project of projects) {
      const { id, previews, images } = project;

      if (!id) {
        console.warn("Project missing an ID. Skipping:", project);
        continue;
      }

      // Step 1: Delete associated images from Cloudinary
      if (Array.isArray(previews) && previews.length > 0) {
        for (const preview of previews) {
          const { public_id } = preview;
          if (public_id) {
            try {
              await deleteImageFromCloudinary(public_id);
            } catch (err) {
              console.error(
                `Error deleting image from Cloudinary (public_id: ${public_id}):`,
                err.message
              );
            }
          }
        }
      }

      if (Array.isArray(images) && images.length > 0) {
        for (const image of images) {
          const { public_id } = image;
          if (public_id) {
            try {
              await deleteImageFromCloudinary(public_id);
            } catch (err) {
              console.error(
                `Error deleting image from Cloudinary (public_id: ${public_id}):`,
                err.message
              );
            }
          }
        }
      }

      // Step 2: Delete the project by ID
      try {
        await deleteProjectByID(id);
        console.log(`Successfully deleted project with ID: ${id}`);
      } catch (err) {
        console.error(`Error deleting project (ID: ${id}):`, err.message);
      }
    }
  } catch (err) {
    console.error("Error during project deletion process:", err.message);
    throw err; // Optionally rethrow for higher-level error handling
  }
}

export async function createEventWithData(eventData, posterFile) {
  try {
    const posterData = await uploadEventPosterToCloudinary(posterFile);
    if (posterData) {
      createEvent(eventData, posterData);
    }
  } catch (error) {
    console.error("Ther was an error creating the event:", error);
  }
}

export async function deleteEvents(eventsArray) {
  const userConfirmation = window.confirm(
    "Sei sicuro di voler procedere? L'azione è irreversibile"
  );

  if (!userConfirmation) {
    return;
  }

  for (const event of eventsArray) {
    const { id, event_images } = event;

    const publicId = event_images[0].public_id;

    try {
      await deleteEventByID(id);
    } catch (error) {
      console.error(`Error deleting event ${id}: ${error.message}`);
    }

    try {
      await deleteImageFromCloudinary(publicId);
    } catch (error) {
      console.error("Error deleting the image from Cloudinary:", error);
    }
  }
}

export async function updateEventWithImage(
  id,
  eventDetails,
  preview, // Single preview image file
  previewID,
  previewsPublicID
) {
  try {
    let previewUrl;
    let imagesUrls;
    let previewWithID;
    const previewFile = preview[0]?.file;
    // Upload preview (single file)
    if (previewFile) {
      console.log("ok");
      await deleteImageFromCloudinary(previewsPublicID);
      previewUrl = await uploadEventPosterToCloudinary(previewFile);
      previewWithID = { ...previewUrl, id: previewID };
    }

    await updateEvent(eventDetails, id, previewWithID);
  } catch (error) {
    console.error("Error updating project with images:", error);
    throw error;
  }
}
