import React from "react";
import styles from "./customModal.module.scss";
import CustomButton from "../custom_button/customButton";
import { motion } from "motion/react";
import Loader from "../Loader";

function CustomModal({
  children,
  title,
  description,
  handleConfirm,
  handleCancel,
  isSubmitting,
}) {
  return (
    <motion.div className={styles.modalBackground}>
      <motion.div
        initial={{ y: "-100%", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ type: "spring", stiffness: 30, dumping: 70 }}
        className={styles.modalContainer}
      >
        <div className={styles.modalHeader}>
          <span className={styles.title}>{title}</span>
          <span className={styles.description}>{description}</span>
        </div>
        <div className={styles.modalContent}>{children}</div>
        <div className={styles.actionContainer}>
          <CustomButton
            title={"Annulla"}
            action={handleCancel}
            type={"delete"}
            disabled={isSubmitting}
          />
          <CustomButton
            title={"Conferma"}
            action={handleConfirm}
            type={"confirm"}
            disabled={isSubmitting}
          />
        </div>
      </motion.div>
      {isSubmitting && <Loader />}
    </motion.div>
  );
}

export default CustomModal;
