import axios from "axios";

const upload_preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
const api_key = process.env.REACT_APP_CLOUDINARY_API_KEY;
const cloud_name = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
const api_secret = process.env.REACT_APP_CLOUDINARY_API_SECRET;

export async function uploadToCloudinary(file) {
  const url = `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "previews");

  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return {
      image_url: response.data.secure_url,
      public_id: response.data.public_id,
    }; // This is the URL of the uploaded image
  } catch (error) {
    console.error("Error uploading to Cloudinary:", error);
    throw error;
  }
}

export async function uploadEventPosterToCloudinary(file) {
  const url = `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "event_poster");

  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return {
      image_url: response.data.secure_url,
      public_id: response.data.public_id,
    }; // This is the URL of the uploaded image
  } catch (error) {
    console.error("Error uploading to Cloudinary:", error);
    throw error;
  }
}

export async function uploadMultipleToCloudinary(files) {
  const url = `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`;

  const uploadedUrls = [];

  for (const file of files) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", upload_preset);

    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      uploadedUrls.push({
        image_url: response.data.secure_url,
        public_id: response.data.public_id,
      });
      console.log(`Uploaded ${file.name}:`, response.data.secure_url);
    } catch (error) {
      console.error(`Error uploading ${file.name}:`, error);
      throw error;
    }
  }

  return uploadedUrls; // Array of secure URLs
}

export async function deleteImageFromCloudinary(publicId) {
  try {
    const response = await axios.post("/.netlify/functions/deleteImage", {
      public_id: publicId,
    });

    if (response.status === 200) {
      console.log("Image deleted successfully");
      return true;
    } else {
      console.error("Failed to delete image");
      return false;
    }
  } catch (error) {
    console.error("Error deleting image from backend:", error);
    throw error;
  }
}

export default {
  uploadToCloudinary,
  uploadMultipleToCloudinary,
  deleteImageFromCloudinary,
};
