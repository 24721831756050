import React, { JSX } from "react";
import AnimatedText from "./AnimatedText";

interface AnimatedUnderlineLinkProps {
  href: string;
  children: React.ReactNode;
  className?: string;
  isLoading: boolean;
}

function AnimatedUnderlineLink({
  href,
  children,
  className = "",
  isLoading,
}: AnimatedUnderlineLinkProps): JSX.Element {
  return (
    <AnimatedText className={className} isLoading={isLoading}>
      <a
        className={`animated-underline ${className}`}
        href={href}
        target="_blank"
      >
        {children}
      </a>
    </AnimatedText>
  );
}

export default AnimatedUnderlineLink;
