import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MdDragIndicator } from "react-icons/md";
import { motion } from "motion/react";
import styles from "../dahboardSections.module.scss";
import { useNavigate } from "react-router-dom";

function EventsTableRow({
  element,
  formatDate,
  isSortable,
  handleCheckboxChange,
  selectedProjects,
  index,
  setSelectedProjects,
}) {
  const navigate = useNavigate();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: element.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    zIndex: isDragging ? 999 : 1,
  };

  function handleClick() {
    setSelectedProjects([]);
    navigate(`/back-office/dashboard/events/${element.id}`);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.1 * index, duration: 0.5 }}
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={
        selectedProjects.includes(element) ? styles.rowSelected : styles.row
      }
      role="button"
      tabIndex={0}
      onClick={() => handleClick()}
    >
      <div className={styles.checkboxColumn}>
        <input
          type="checkbox"
          className={styles.customCheckbox}
          checked={selectedProjects.includes(element)}
          onChange={() => handleCheckboxChange(element)}
          onClick={(event) => event.stopPropagation()}
        />
      </div>
      <div className={styles.cell}>
        <img
          className={styles.rowPreview}
          src={element.event_images[0]?.image_url}
          alt="Preview"
        />
      </div>
      <div className={styles.cell}>{element.title}</div>
      <div className={styles.cell}>{formatDate(element.event_date)}</div>
      <div className={styles.cell}>{element.location}</div>
      {isSortable && (
        <div {...listeners} className={styles.grabContainer}>
          <MdDragIndicator />
        </div>
      )}
    </motion.div>
  );
}

export default EventsTableRow;
