import React from "react";
import styles from "./customButton.module.scss";

function CustomButton({
  type = "confirm",
  title,
  icon,
  action,
  disabled = false,
}) {
  function returnStyle(type) {
    switch (type) {
      case "confirm":
        return styles.confirmButton;
      case "delete":
        return styles.deleteButton;
      case "neutral":
        return styles.neutralButton;
    }
  }

  return (
    <button
      disabled={disabled}
      className={returnStyle(type)}
      onClick={(e) => action && action(e)}
      type="button"
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {title}
    </button>
  );
}

export default CustomButton;
