import React from "react";
import styles from "./dragAndDrop.module.scss";
import { motion } from "motion/react";
import { deleteImage } from "../../../../Server/db";

function CurrentImagePreview({ setPreviews, src, index, publicID, imageID }) {
  // Remove preview when canceled
  const removePreview = (event, index) => {
    event.stopPropagation();
    try {
      deleteImage(imageID, publicID);
      setPreviews((prevState) => prevState.filter((_, i) => i !== index));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.1 * index }}
      key={index}
      className={styles.currentPreviewContainer}
    >
      <img src={src} alt={`Preview-${index}`} className={styles.previewImage} />
      <button
        onClick={(event) => removePreview(event, index)}
        className={styles.previewButton}
      >
        ✕
      </button>
    </motion.div>
  );
}

export default CurrentImagePreview;
