import React, { JSX } from "react";
import { motion } from "framer-motion"; // Use framer-motion, not "motion/react"
import { useTranslation } from "react-i18next";
import workInProgressImage from "../Utils/Assets/work_in_progress.png";
import AnimatedText from "./website/Layouts/AnimatedText";
import { useWebsiteContext } from "./website/hooks/useWebsiteContext";
import AnimatedUnderlineLink from "./website/Layouts/AnimatedUnderline";
import { IoLogoInstagram } from "react-icons/io5";
import AnimatedUnderlineButton from "./website/Layouts/AnimatedUndelineButton";

function ComingSoon(): JSX.Element {
  const { t, i18n } = useTranslation(); // Pass "login" as the namespace
  const { isLoading } = useWebsiteContext();

  function handleClick(language: string): void {
    i18n.changeLanguage(language);
  }

  return (
    <motion.div className="comingSoonContainer">
      <div className="languageSwitch">
        <AnimatedUnderlineLink
          className="center"
          href="https://www.instagram.com/rad_architetture"
          isLoading={!isLoading}
        >
          <IoLogoInstagram fontSize={"2rem"} />
        </AnimatedUnderlineLink>
        <div className="languageToggle">
          <AnimatedUnderlineButton
            className={i18n.language === "it" ? "selected" : ""}
            isLoading={!isLoading}
            handleClick={() => handleClick("it")}
          >
            IT
          </AnimatedUnderlineButton>
          <span>/</span>
          <AnimatedUnderlineButton
            className={i18n.language === "en" ? "selected" : ""}
            isLoading={!isLoading}
            handleClick={() => handleClick("en")}
          >
            ENG
          </AnimatedUnderlineButton>
        </div>
      </div>
      <div className="logoContainer">
        <div className="comingsoonLlogo" />

        <div className="comingsoonCompanyName">
          <AnimatedText
            isLoading={!isLoading}
            className="logoStrong"
            delay={0.2}
          >
            RAD
          </AnimatedText>
          <AnimatedText isLoading={!isLoading} className="logoGrey" delay={0.5}>
            architetture
          </AnimatedText>
        </div>
        <AnimatedText
          isLoading={!isLoading}
          delay={0.9}
          className={"companyLocation"}
        >
          {t("splash.location")}
        </AnimatedText>
      </div>
      <div className="comingSoonMessageConrainer">
        <img
          src={workInProgressImage}
          className="comingSoonImage"
          alt="Work in Progress"
        />
        <AnimatedText isLoading={!isLoading} className="comingSoonTitle">
          {t("comingsoon.construction")}
        </AnimatedText>
        <AnimatedText
          delay={0.2}
          isLoading={!isLoading}
          className="comingSoonSubtitle"
        >
          {t("comingsoon.launch")}
        </AnimatedText>
        <AnimatedText
          delay={0.3}
          isLoading={!isLoading}
          className="comingSoonSubtitle"
        >
          {t("comingsoon.tuned")}
        </AnimatedText>
      </div>
      <div className="ComingSoonFooter">
        <div className="comingSoonFooterSection-start">
          <AnimatedUnderlineLink
            className="footerlink"
            isLoading={!isLoading}
            href="tel:+390559060092"
          >
            +39 055 9060092
          </AnimatedUnderlineLink>
          <AnimatedUnderlineLink
            className="footerlink"
            isLoading={!isLoading}
            href="mailto:info@radarchitetture.com"
          >
            info@radarchitetture.com
          </AnimatedUnderlineLink>
        </div>
        <div className="comingSoonFooterSection-center">
          <AnimatedText className="footerlink" isLoading={!isLoading}>
            RAD architetture © 2018
          </AnimatedText>
        </div>
        <div className="comingSoonFooterSection-end">
          <AnimatedUnderlineLink
            href="https://maps.app.goo.gl/72DsJxjoTbNkreLM9"
            isLoading={!isLoading}
          >
            Via Francesco Valori, 2a
          </AnimatedUnderlineLink>
          <AnimatedUnderlineLink
            href="https://maps.app.goo.gl/72DsJxjoTbNkreLM9"
            isLoading={!isLoading}
          >
            50132 Firenze FI
          </AnimatedUnderlineLink>
        </div>
      </div>
    </motion.div>
  );
}

export default ComingSoon;
