import React, { useState } from "react";
import styles from "./loginStyles.module.scss";
import InputLayout from "./InputLayout";
import { useNavigate } from "react-router-dom";
import supabase from "../../../Server/supabase";
import toast from "react-hot-toast";

export default function NewPassword() {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState({
    password: "",
    passwordConfirmation: "",
    login: "",
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function validateForm() {
    const newErrors = {};
    if (!password) {
      newErrors.password = `Inserisci la nuova password`;
    }
    if (!passwordConfirmation) {
      newErrors.passwordConfirmation = `Ripeti la password`;
    }
    if (password !== passwordConfirmation) {
      toast.error("Le password devono essere uguali.");
      newErrors.login = "Le password inserite devono essere uguali";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      setErrors(""); // Clear previous errors

      try {
        const { error } = await supabase.auth.updateUser({
          password: passwordConfirmation,
        });

        if (error) {
          setErrors({ login: error.message });
        } else {
          // If successful, navigate to login or show success message
          toast.success("Password impostata correttamente.");
          console.log("Password reset successfully!");
          navigate("/back-office/login"); // Or show a success message
        }
      } catch (err) {
        setErrors({
          login: "Si è verificato un errore. Riprova" + err.message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <h2 style={{ textAlign: "left", margin: "2rem" }}>Nuova Password</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <InputLayout
            label="Password"
            type="password"
            value={password}
            error={errors.password}
            onChange={({ target }) => setPassword(target.value)}
            placeholder="Enter your password"
          />
          <InputLayout
            label="Conferma Password"
            type="password"
            value={passwordConfirmation}
            error={errors.passwordConfirmation}
            onChange={({ target }) => setPasswordConfirmation(target.value)}
            placeholder="Enter your password"
          />
          <button disabled={loading} className={styles.button} type="submit">
            Conferma
          </button>
          <p
            style={{ textAlign: "center", fontSize: "12px", color: "#ab2913" }}
          >
            {errors.login}
          </p>
        </form>
        <span
          role="button"
          tabIndex={0}
          onClick={() => navigate("/back-office/login")}
          className={styles.secondaryButton}
        >
          Accedi qui?
        </span>
      </div>
      <div className={styles.logoContainer}>
        <div className={styles.logo} />
        <span className={styles.logoText}>
          <strong>RAD</strong> architetture
        </span>
        <span className={styles.secondaryText}>Florence</span>
      </div>
    </div>
  );
}
