import React, { useCallback, useState } from "react";
import { motion } from "motion/react";
import { useNavigate } from "react-router-dom";
import styles from "./dahboardSections.module.scss";
import { GrUpdate } from "react-icons/gr";
import { MdClose } from "react-icons/md";
import CustomButton from "./layouts/custom_button/customButton";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";

export default function FunctionBar({
  tableTitle,
  sortedArray = [],
  selectedProjects = [],
  handleAdd,
  handleDelete,
  handleUpdate,
  searchInput,
  setSearchInput,
  isLoading,
}) {
  function handleChange(e) {
    setSearchInput(e.target.value);
  }

  return (
    <div className={styles.funcitonbarWrapper}>
      <div className={styles.start}>
        <div
          tabIndex={0}
          role="button"
          onClick={handleUpdate}
          className={styles.iconContainer}
        >
          <motion.div
            animate={isLoading ? { rotate: [0, 180, 360] } : { rotate: 0 }}
            transition={
              isLoading ? { duration: 1, repeat: Infinity, ease: "linear" } : {}
            }
          >
            <GrUpdate className={styles.icon} />
          </motion.div>
          <span
            style={{
              borderLeft: "1px solid rgb(0,0,0)",
              padding: "0 10px",
              margin: "0 10px",
            }}
          >{`${tableTitle} totali: ${sortedArray.length}`}</span>
        </div>
      </div>
      <div className={styles.center}>
        <div className={styles.searchBarContainer}>
          <input
            className={styles.searchBar}
            placeholder="Filtra tabella"
            value={searchInput}
            onChange={(e) => handleChange(e)}
            type="text"
          />
          {searchInput?.length > 0 && (
            <MdClose
              role="button"
              tabIndex={0}
              onClick={() => setSearchInput("")}
              className={styles.icon}
            />
          )}
        </div>
      </div>
      <div className={styles.end}>
        <CustomButton
          title={
            selectedProjects.length === 0
              ? `Nuovo ${tableTitle.slice(0, -1)}o`
              : `Elimina ${tableTitle}`
          }
          action={
            selectedProjects.length === 0
              ? handleAdd
              : () => handleDelete(selectedProjects)
          }
          type={selectedProjects.length === 0 ? "confirm" : "delete"}
          icon={
            selectedProjects.length === 0 ? (
              <IoMdAdd className={styles.icon} />
            ) : (
              <MdDelete className={styles.icon} />
            )
          }
        />
      </div>
    </div>
  );
}
