import { motion } from "motion/react";
import styles from "../dahboardSections.module.scss";
import { useDashboardContext } from "../../../hooks/useDashboardContext";
import Loader from "./Loader";

export default function PageLayout({ children }) {
  const { isLoading } = useDashboardContext();
  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ type: "spring", stiffness: 40, dumping: 190 }}
      className={styles.routerContainer}
    >
      {isLoading && <Loader />}
      {children}
    </motion.div>
  );
}
