import React from "react";
import { motion } from "motion/react";

export default function Loader() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(240,240,240,0.3)",
        zIndex: 9999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <motion.div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          backgroundColor: "rgba(139, 139, 3, 0.1)",
        }}
        animate={{
          width: [70, 100, 70],
          height: [70, 100, 70],
        }}
        transition={{
          duration: 2, // Duration for one cycle of the animation
          repeat: Infinity, // Infinite loop
          repeatType: "loop", // Repeat the animation in a loop
          ease: "easeInOut", // Easing function
        }}
      >
        <div
          style={{
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            backgroundColor: "#fefc04",
          }}
        />
      </motion.div>
    </motion.div>
  );
}
