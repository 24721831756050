import React from "react";

function Setting_Section({ children }) {
  return (
    <div style={{ borderBottom: "1px solid rgba(0,0,0,0.4)", padding: "1rem" }}>
      {children}
    </div>
  );
}

export default Setting_Section;
