import React from "react";
import styles from "./customInput.module.scss";

const CustomInput = ({
  label,
  type = "text",
  value,
  onChange,
  placeholder,
  error,
  helperText,
  required = false,
  disabled = false,
  name,
  errorMessage,
  multiline = true,
  className = "",
}) => {
  const inputId = name || label?.toLowerCase().replace(/\s+/g, "-");

  return (
    <div className={styles.inputContainer}>
      {label && (
        <label htmlFor={inputId} className={styles.label}>
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}

      <input
        id={inputId}
        type={type}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        className={error ? styles.error : styles.input}
      />

      {error && <span className={styles.errorMessage}>{errorMessage}</span>}

      {helperText && !error && (
        <span className={styles.helperText}>{helperText}</span>
      )}
    </div>
  );
};

export default CustomInput;
