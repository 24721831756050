import { createClient } from "@supabase/supabase-js";
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_SERVICE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey, {
  detectSessionInUrl: false,
});

async function fetchUsers() {
  try {
    // Execute a raw SQL query to get data from the auth.users table
    const { data, error } = await supabase.auth.admin.listUsers();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching users:", error.message);
  }
}

async function fetchProjects() {
  try {
    const { data, error } = await supabase.from("projects").select(`
          *,
          authors(*),
          images(*),
          previews(*),
        `);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error fetching projects:", error.message);
  }
}

async function fetchProjectsPreviews() {
  try {
    const { data, error } = await supabase
      .from("projects")
      .select(
        `
    *,
    previews(*),
    images(*),
    positions(*)
  `
      )
      .order("position", { ascending: true, foreignTable: "positions" });

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error fetching projects:", error.message);
  }
}

async function fetchProjectsByID(id) {
  try {
    const { data, error } = await supabase
      .from("projects")
      .select(
        `
            *,
            authors(*),
            images(*),
            previews(*)
          `
      )
      .eq("id", id);

    if (error) {
      throw error;
    }

    console.log("Projects with related data:", data);
    return data;
  } catch (error) {
    console.error("Error fetching projects:", error.message);
  }
}

async function createProject(projectDetails, authors, images, preview) {
  try {
    // Step 1: Insert the project into the 'projects' table
    const { data: projectData, error: projectError } = await supabase
      .from("projects")
      .insert([projectDetails])
      .select(); // Return the inserted project

    if (projectError) throw projectError;

    const projectId = projectData[0].id; // Assume the project ID is returned

    // Step 2: Prepare and insert related data
    // Insert authors
    const authorsWithProjectId = authors.map((author) => ({
      ...author,
      project_id: projectId,
    }));

    const { error: authorsError } = await supabase
      .from("authors")
      .insert(authorsWithProjectId);

    if (authorsError) throw authorsError;

    // Insert images
    const imagesWithProjectId = images.map((image) => ({
      ...image,
      project_id: projectId,
    }));

    const { error: imagesError } = await supabase
      .from("images")
      .insert(imagesWithProjectId);

    if (imagesError) throw imagesError;

    // Insert preview
    const previewWithProjectId = { ...preview, project_id: projectId };

    const { error: previewError } = await supabase
      .from("previews")
      .insert([previewWithProjectId]);

    if (previewError) throw previewError;

    return { success: true, projectId };
  } catch (error) {
    console.error("Error creating project:", error.message);
    return { success: false, error: error.message };
  }
}

async function updateProject(
  projectId,
  projectDetails,
  authors,
  images,
  preview
) {
  try {
    // Step 1: Update the project in the 'projects' table
    const { data: updatedProject, error: projectError } = await supabase
      .from("projects")
      .update(projectDetails)
      .eq("id", projectId)
      .select(); // Return the updated project

    if (projectError) throw projectError;

    // Step 2: Update or Insert authors
    const authorsWithProjectId = authors.map((author) => {
      if (author.project_id) {
        return author;
      } else {
        return {
          ...author,
          project_id: projectId,
        };
      }
    });

    const updatedAuthors = authorsWithProjectId.filter(
      (author) => author.id !== (null || undefined)
    );

    const newAuthors = authorsWithProjectId.filter(
      (author) => author.id === (null || undefined)
    );

    // Fetch existing authors for deletion logic
    const existingAuthors = await supabase
      .from("authors")
      .select("id")
      .eq("project_id", projectId);

    if (existingAuthors.error) throw existingAuthors.error;

    const existingAuthorIds = existingAuthors.data.map((author) => author.id);
    const updatedAuthorIds = updatedAuthors.map((author) => author.id);

    // Identify authors to delete
    const authorsToDelete = existingAuthorIds.filter(
      (id) => !updatedAuthorIds.includes(id)
    );

    if (authorsToDelete.length > 0) {
      const { error: deleteError } = await supabase
        .from("authors")
        .delete()
        .in("id", authorsToDelete);

      if (deleteError) throw deleteError;
    }

    // Upsert updated authors
    const { error: updateAuthorsError } = await supabase
      .from("authors")
      .upsert(updatedAuthors, { onConflict: ["id"] });

    if (updateAuthorsError) throw updateAuthorsError;

    // Insert new authors
    const { error: newAuthorsError } = await supabase
      .from("authors")
      .insert(newAuthors);

    if (newAuthorsError) throw newAuthorsError;

    // Step 4: Update or insert preview
    if (preview) {
      const previewWithProjectId = {
        ...preview,
        project_id: projectId,
      };

      const { error: previewError } = await supabase
        .from("previews")
        .upsert([previewWithProjectId], { onConflict: ["id"] });

      if (previewError) throw previewError;
    }

    // Step 3: Update or insert images
    if (Array.isArray(images) && images.length > 0) {
      const imagesWithProjectId = images.map((image) => ({
        ...image,
        project_id: projectId,
      }));

      const { error: imagesError } = await supabase
        .from("images")
        .insert(imagesWithProjectId);

      if (imagesError) throw imagesError;
    }

    return { success: true, projectId };
  } catch (error) {
    console.error("Error updating project:", error.message);
    return { success: false, error: error.message };
  }
}

async function deleteProjectByID(id) {
  try {
    const { data, error } = await supabase
      .from("projects")
      .delete()
      .eq("id", id);
  } catch (error) {
    console.error("Error fetching project:", error);
  }
}

async function deleteImageByID(id) {
  try {
    const { data, error } = await supabase.from("images").delete().eq("id", id);
  } catch (error) {
    console.error("Error deleting image:", error);
  }
}

async function deletePreviewByID(id) {
  try {
    const { data, error } = await supabase
      .from("previews")
      .delete()
      .eq("id", id);
  } catch (error) {
    console.error("Error deleting image:", error);
  }
}

async function deleteAuthorByID(id) {
  try {
    const { data, error } = await supabase
      .from("authors")
      .delete()
      .eq("id", id);
  } catch (error) {
    console.error("Error deleting author:", error);
  }
}

async function updatePositionsInSupabase(updatedPositions) {
  try {
    const { error } = await supabase
      .from("positions")
      .upsert(updatedPositions, { onConflict: ["id"] });

    if (error) {
      console.error("Error updating positions in Supabase:", error);
    }
  } catch (error) {
    console.error("Error updating positions:", error);
  }
}

async function fetchEvents() {
  try {
    const { data, error } = await supabase.from("events").select(`
      *,
      event_images(*)
    `);
    if (error) {
      console.error("There was an error fetching the events:", error);
    }
    return data;
  } catch (error) {
    console.error("There was an error fetching the events:", error);
  }
}

async function fetchEventsByID(eventID) {
  try {
    const { data, error } = await supabase
      .from("events")
      .select("*, event_images(*)")
      .eq("id", eventID);
    if (error) {
      console.error("There was an error fetching the event:", error);
    }
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function deleteEventByID(eventID) {
  try {
    const { data, error } = await supabase
      .from("events")
      .delete()
      .eq("id", eventID);
  } catch (error) {
    console.error("There was an error deleting the event:", error);
  }
}

async function createEvent(event, posterData) {
  try {
    const { data: eventData, error: eventDataError } = await supabase
      .from("events")
      .insert([event])
      .select();
    if (eventDataError) {
      console.error("There was an error creating the event:", eventDataError);
    }

    const eventID = eventData[0].id;
    const posterDataDataWithEventID = { ...posterData, event_id: eventID };
    const { data, error } = await supabase
      .from("event_images")
      .insert(posterDataDataWithEventID);
    if (error) {
      console.error("There was an error inserting the poster data:", error);
    }
  } catch (error) {
    console.error("There was an error creating the event:", error);
  }
}

async function updateEvent(eventDetails, eventID, preview) {
  try {
    const { data: updatedEvent, error: eventError } = await supabase
      .from("events")
      .update(eventDetails)
      .eq("id", eventID)
      .select(); // Return the updated project

    if (eventError) throw eventError;

    if (preview) {
      const previewWithEventID = {
        ...preview,
        event_id: eventID,
      };

      const { error: previewError } = await supabase
        .from("event_images")
        .upsert([previewWithEventID], { onConflict: ["id"] });

      if (previewError) throw previewError;
    }
  } catch (error) {
    console.error("There was an error updating the event:", error);
  }
}

export default supabase;

export {
  fetchUsers,
  fetchProjects,
  fetchProjectsByID,
  fetchProjectsPreviews,
  updateProject,
  createProject,
  deleteProjectByID,
  deleteImageByID,
  deletePreviewByID,
  deleteAuthorByID,
  updatePositionsInSupabase,
  fetchEvents,
  fetchEventsByID,
  deleteEventByID,
  createEvent,
  updateEvent,
};
