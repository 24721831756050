import React from "react";
import { motion, AnimatePresence } from "motion/react";

function AnimatedText({ children, className, delay = 0, isLoading }) {
  return (
    <motion.div className={`animatedWrapper ${className}`}>
      <AnimatePresence mode="wait">
        {isLoading && (
          <motion.span
            key={`text-${children}`}
            className={className}
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{
              y: -100,
              transition: {
                duration: 0.6,
              },
            }}
            transition={{
              duration: 0.8,
              bounce: 0.2,
              delay,
              ease: [0.25, 0.46, 0.45, 0.94],
            }}
          >
            {children}
          </motion.span>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default AnimatedText;
