import React, { createContext, useState, useContext } from "react";

// Create the context with a default value
const DashboardContext = createContext();

// DashboardContext provider component
export const DashboardProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <DashboardContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </DashboardContext.Provider>
  );
};

// Custom hook to use DashboardContext
export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider"
    );
  }
  return context;
};
