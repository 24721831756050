import React, { useCallback, useEffect, useState, useMemo } from "react";
import PageLayout from "../layouts/PageLayout";
import FunctionBar from "../FunctionBar";
import styles from "../dahboardSections.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { SortableTable } from "../SortableTable";
import { fetchEvents } from "../../../../../Server/supabase";
import EventsTableRow from "./EventsRow";
import toast from "react-hot-toast";
import { deleteEvents } from "../../../../../Server/db";

export default function Events() {
  const [events, setEvents] = useState([]);
  const [sortedEvents, setSortedEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  const updateSortedEvents = useMemo(() => {
    return [...events].sort((a, b) => {
      const dateA = new Date(a.event_date || Infinity);
      const dateB = new Date(b.event_date || Infinity);
      return dateB - dateA;
    });
  }, [events]);

  useEffect(() => {
    setSortedEvents(
      updateSortedEvents.filter((element) =>
        element.title.toLocaleLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [updateSortedEvents, searchInput]);

  async function handlFetchUpdate() {
    try {
      const eventsArray = await fetchEvents();
      setEvents(eventsArray);
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      setSearchInput("");
    }
  }

  const location = useLocation();
  useEffect(() => {
    const update = setTimeout(() => {
      handlFetchUpdate();
    }, 500);

    return () => clearTimeout(update);
  }, [location.pathname]);

  const addEvent = useCallback(() => {
    navigate("/back-office/dashboard/events/new-event");
  }, []);

  function handleUpdate() {
    setLoading(true);
    try {
      handlFetchUpdate();
    } catch (error) {
      console.error(error);
      toast.error("Si è verificato un errore durante l'aggiornamento.");
    } finally {
      setLoading(false);
      toast.success("Progetti aggiornati.");
    }
  }

  function formatDate(rawDate) {
    const date = new Date(rawDate);
    const formattedDate = `${date.toLocaleDateString()}`;
    return formattedDate;
  }

  const handleDeleteEvents = async () => {
    setLoading(true);
    try {
      await deleteEvents(selectedEvents);
      setSelectedEvents([]);
      handlFetchUpdate();
    } catch (error) {
      console.error(error);
      toast.success("Si è verificato un errore. Riprova.");
    } finally {
      setLoading(false);
      toast.success("Progetti eliminati con successo.");
    }
  };

  return (
    <PageLayout>
      <h2 className={styles.title}>Events</h2>
      <FunctionBar
        tableTitle={"Eventi"}
        sortedArray={sortedEvents}
        selectedProjects={selectedEvents}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        isLoading={loading}
        handleAdd={addEvent}
        handleUpdate={handleUpdate}
        handleDelete={handleDeleteEvents}
      />
      <SortableTable
        isSortable={false}
        selectedProjects={selectedEvents}
        sortedItems={sortedEvents}
        setSortedItems={setSortedEvents}
        setSelectedProjects={setSelectedEvents}
        loading={loading}
        setLoading={setLoading}
        headers={["", "Copertina", "Titolo", "Data evento", "Luogo", ""]}
        RowComponent={EventsTableRow}
        formatDate={formatDate}
      />
    </PageLayout>
  );
}
