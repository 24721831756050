import React from "react";
import { Outlet, Link } from "react-router-dom";
import DashboardRoutes from "./DashboardRoutes";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuthlistener";
import styles from "./dashboard.module.scss";
import { DashboardProvider } from "../hooks/useDashboardContext";

import BackofficeNavbar from "./BackofficeNavbar";
import Sidebar from "./Sidebar";

export default function Dashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  if (!user) {
    navigate("/back-office/login");
  }

  return (
    <DashboardProvider>
      <div className={styles.dashboardContainer}>
        <BackofficeNavbar />
        <div className={styles.routerContainer}>
          <Sidebar />

          <div style={{ flex: 1 }}>
            <DashboardRoutes />
          </div>
        </div>
      </div>
    </DashboardProvider>
  );
}
