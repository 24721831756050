import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./Components/styles.scss";
//Component imports
import { WebsiteProvider } from "./Components/website/hooks/useWebsiteContext";
//Website imports
import Home from "./Components/website/Home";
import ComingSoon from "./Components/ComingSoon.tsx";

//Backoffice imports
import Login from "./Components/backoffice/Login/Login";
import Reset from "./Components/backoffice/Login/Reset";
import NewPassword from "./Components/backoffice/Login/NewPassword";
import Dashboard from "./Components/backoffice/dashboard/Dashboard";
import NotFound from "./Components/NotFound.tsx";
import { Toaster } from "react-hot-toast";
import SplashScreen from "./Components/website/privecy_screens/SplashScreen";

const App = () => {
  return (
    <WebsiteProvider>
      <Router>
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="/back-office/login" element={<Login />} />
          <Route path="/back-office/reset" element={<Reset />} />
          <Route path="/back-office/new-password" element={<NewPassword />} />
          <Route path="/back-office/sign-up" element={<NewPassword />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/back-office/dashboard/*" element={<Dashboard />} />
        </Routes>
      </Router>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          removeDelay: 1000,
          style: {
            background: "#fff",
            color: "#070707",
            fontSize: "1rem",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            iconTheme: {
              primary: "green",
              secondary: "white",
            },
          },
        }}
      />
      <SplashScreen />
    </WebsiteProvider>
  );
};

export default App;
