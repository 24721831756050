import React from "react";
import styles from "./dragAndDrop.module.scss";
import { motion } from "motion/react";

function ImagePreview({ setPreviews, src, index }) {
  // Remove preview when canceled
  const removePreview = (event, index) => {
    event.stopPropagation();
    setPreviews((prevState) => prevState.filter((_, i) => i !== index));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.1 * index }}
      key={index}
      className={styles.previewContainer}
    >
      <img
        src={src.preview ? src.preview : src.image_url}
        alt={`Preview-${index}`}
        className={styles.previewImage}
      />
      <button
        onClick={(event) => removePreview(event, index)}
        className={styles.previewButton}
      >
        ✕
      </button>
    </motion.div>
  );
}

export default ImagePreview;
